<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input placeholder="请输入姓名" v-model="queryInfo.userName" clearable @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <!-- <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加用户</el-button>
        </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="userlist" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="userId" label="用户id"></el-table-column>
        <el-table-column prop="userName" label="姓名"></el-table-column>
        <el-table-column prop="lastBalance" label="余额"></el-table-column>
        <el-table-column prop="buildingAddress.addressName" label="楼"></el-table-column>
        <el-table-column prop="floorAddress.addressName" label="楼层"></el-table-column>
        <el-table-column prop="phoneNum" label="电话"></el-table-column>
        <el-table-column prop="validName" label="是否有效"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="充值"
              :enterable="false"
              placement="top"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                circle
                @click="showEditDialog(scope.row.userId)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              :enterable="false"
              placement="top"
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="removeUserById(scope.row.userId)"
            ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="退款"
              :enterable="false"
              placement="top"
            >
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="refundUserById(scope.row.userId)"
            ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addUserForm"
        ref="addUserFormRef"
        :rules="addUserFormRules"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addUserForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addUserForm.password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addUserForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="addUserForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户余额的对话框 -->
    <el-dialog
      title="用户充值"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="editUserBalanceForm"
        ref="editUserBalanceFormRef"
        :rules="editUserBalanceFormRules"
        label-width="80px"
      >
        <el-form-item label="用户id">
          <el-input v-model="editUserBalanceForm.userId" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="editUserBalanceForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="editUserBalanceForm.phoneNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="余额">
          <el-input v-model="editUserBalanceForm.lastBalance" disabled></el-input>
        </el-form-item>
        <el-form-item label="充值金额" prop="rechargeAmount">
          <el-input v-model="editUserBalanceForm.rechargeAmount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserBalance">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户余额的对话框 -->

  </div>
</template>

<script>
export default {
  data () {
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error('请输入合法邮箱'))
    }
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error('请输入合法的手机号码'))
    }
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        userName: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 5
      },
      userlist: [],
      totle: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: '',
        password: '',
        email: '',
        mobile: ''
      },
      // 用户添加表单验证规则
      addUserFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '用户名的长度在2～10个字',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: '用户密码的长度在6～18个字',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      // 修改用户
      editDialogVisible: false,
      editUserBalanceForm: {},
      // 编辑用户表单验证
      editUserBalanceFormRules: {
        rechargeAmount: [
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          {
                required: true,
                pattern: /^(0\.[1-9]\d*|[1-9]\d*(\.\d+)?)$/,
                message: '请输入大于0的数',
                trigger: 'blur'
          }
        ],
      },
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    async getUserList () {
      //清空
      this.userlist=[];
      this.totle=0;
      //查询
      const { data: res } = await this.$http.get('/orderuser/query', {
        params: this.queryInfo
      })
      if (res.code != 20) {
        return this.$message.error(res.msg)
      }else{
        this.userlist = res.data.result;
        this.totle = res.data.total;
      }
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagesize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagenum = newSize
      this.getUserList()
    },
    // 监听 switch开关 状态改变
    async userStateChanged (userInfo) {
      // console.log(userInfo)
      const { data: res } = await this.$http.put(
        `users/${userInfo.id}/state/${userInfo.mg_state}`
      )
      if (res.meta.status !== 200) {
        userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败')
      }
      this.$message.success('更新用户状态成功！')
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addUserFormRef.resetFields()
    },
    // 添加用户
    addUser () {
      // 提交请求前，表单预验证
      this.$refs.addUserFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        const { data: res } = await this.$http.post('users', this.addUserForm)
        if (res.meta.status != 20) {
          this.$message.error('添加用户失败！')
        }
        this.$message.success('添加用户成功！')
        // 隐藏添加用户对话框
        this.addDialogVisible = false
        this.getUserList()
      })
    },
    // 显示编辑用户信息
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('/orderuser/selectuser?userId=' + id)
      if (res.code != 20) {
        return this.$message.error('查询用户信息失败！')
      }else{
        this.editUserBalanceForm = res.data
        this.editDialogVisible = true
      }
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed () {
      this.$refs.editUserBalanceFormRef.resetFields()
    },
    // 修改用户余额信息
    editUserBalance () {
      // 提交请求前，表单预验证
      this.$refs.editUserBalanceFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        const { data: res } = await this.$http.post('/orderuser/recharge',
          {
            userId: this.editUserBalanceForm.userId,
            rechargeAmount: this.editUserBalanceForm.rechargeAmount
          }
        )
        if (res.code != 20) {
          this.$message.error(res.msg)
        }else{
          this.$message.success('更新用户余额成功！')
        }
        // 隐藏添加用户对话框
        this.editDialogVisible = false
        this.getUserList()
      })
    },
    // 删除用户
    async removeUserById (id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }else{
        const { data: res } = await this.$http.delete('/orderuser/delete?userId=' + id)
        if (res.code != 20) return this.$message.error(res.msg)
        this.$message.success('删除用户成功！')
        this.getUserList()
      }
    },

    // 退还余额
    async refundUserById (id) {
      const confirmResult = await this.$confirm(
        '确认退款吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消退款')
      }else{
        const { data: res } = await this.$http.post('/orderuser/refund', {userId:id})
        if (res.code !== 20) return this.$message.error(res.msg)
        this.$message.success('退款成功！')
        this.getUserList()
      }
    },
  }
}
</script>

<style lang="less" scoped>
</style>
