<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>餐品管理</el-breadcrumb-item>
      <el-breadcrumb-item>餐品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 面包屑导航区 -->

    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-date-picker
              v-model="queryInfo.mealBelongDate"
              type="date"
              placeholder="选择所属日期"
              value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-button icon="el-icon-search" @click="getMealList"></el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加餐品</el-button>
        </el-col>
      </el-row>
      <!-- 搜索 添加 -->

      <!-- 餐品列表区域 -->
      <el-table :data="mealList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="mealId" label="餐id"></el-table-column>
        <el-table-column prop="mealName" label="餐名"></el-table-column>
        <el-table-column prop="mealDescription" label="餐描述"></el-table-column>
        <el-table-column prop="mealBelongDate" label="所属日期"></el-table-column>
        <el-table-column prop="mealTypeName" label="类型"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              :enterable="false"
              placement="top"
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="showEditDialog(scope.row.mealId)"
            ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              :enterable="false"
              placement="top"
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="removeMealById(scope.row.mealId)"
            ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 餐品列表区域 -->
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      <!-- 分页区域 -->
    </el-card>
    <!-- 卡片视图 -->

    <!-- 添加餐品的对话框 -->
    <el-dialog title="添加餐品" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addMealForm"
        ref="addMealFormRef"
        :rules="addMealFormRules"
        label-width="80px"
      >
        <el-form-item label="所属日期" prop="mealBelongDate">
          <!-- <el-input v-model="addMealForm.mealBelongDate"></el-input> -->
          <el-date-picker
              v-model="addMealForm.mealBelongDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              @change="changeMealBelongDate">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="餐名" prop="mealName">
          <!-- <el-input v-model="addMealForm.mealName"></el-input> -->
            <el-radio v-for="(item) in mealNameList" 
            v-model="addMealForm.mealName"
            :key="item.constantId"
            :label="item.constantName"
            :disabled="handleDisabled(item)"></el-radio>
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="selectedMealTypeObj.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="餐描述" prop="mealDescription">
          <el-input v-model="addMealForm.mealDescription"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="addMealForm.price"></el-input>
        </el-form-item>
        <el-form-item label="餐图片">
          <el-radio v-for="(item) in mealImageList" 
          v-model="addMealForm.mealSmallImg"
          :key="item.mealImageId"
          :label="item.mealImageUrl"
          >{{item.mealImageName}}<img :src="serverBaseUrl + item.mealImageUrl" width="50" height="50"/></el-radio>
        </el-form-item>
      </el-form>
      <!-- 内容主体 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMeal">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加餐品的对话框 -->

    <!-- 修改餐品的对话框 -->
    <el-dialog
      title="修改餐品信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="editMealForm"
        ref="editMealFormRef"
        :rules="editMealFormRules"
        label-width="100px"
      >
        <el-form-item label="餐id">
          <el-input v-model="editMealForm.mealId" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属日期" prop="mealBelongDate">
          <el-input v-model="editMealForm.mealBelongDate" disabled></el-input>
        </el-form-item>
        <el-form-item label="餐名" prop="mealName">
          <el-input v-model="editMealForm.mealName" disabled></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="mealType">
          <el-input v-model="editMealForm.mealTypeName" disabled></el-input>
        </el-form-item>
        <el-form-item label="餐描述" prop="mealDescription">
          <el-input v-model="editMealForm.mealDescription"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="editMealForm.price"></el-input>
        </el-form-item>
      </el-form>
      <!-- 内容主体 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editMeal">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改餐品的对话框 -->

  </div>
</template>

<script>
import { serverBaseUrl } from "@/utils/common";

export default {
  data () {
    return {
      // 获取餐品列表查询参数对象
      queryInfo: {
        mealBelongDate: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      mealList: [],
      totle: 0,
      // 餐名列表
      mealNameList:[],
      // 餐品图片列表
      mealImageList:[],
      // 添加餐品对话框
      addDialogVisible: false,
      // 餐品添加
      addMealForm: {
        mealId:'',
        mealName: '',
        mealDescription: '',
        mealBelongDate: '',
        mealType: '',
        price:'',
        mealSmallImg:''
      },
      // 餐品添加表单验证规则
      addMealFormRules: {
        mealBelongDate: [
          { required: true, message: '请输入餐所属日期', trigger: 'blur' },
        ],
        mealName: [
          { required: true, message: '请输入餐名', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '餐名的长度在2～10个字',
            trigger: 'blur'
          }
        ],
        mealDescription: [
          { required: true, message: '请输入餐描述', trigger: 'blur' },
          {
            min: 2,
            max: 30,
            message: '餐描述的长度在2～30个字',
            trigger: 'blur'
          }
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
          {
            required: true,
            pattern: /^(0\.[1-9]\d*|[1-9]\d*(\.\d+)?)$/,
            message: '请输入大于0的数',
            trigger: 'blur'
          }
        ],
      },
      // 修改餐品
      editDialogVisible: false,
      editMealForm: {},
      // 编辑餐品表单验证
      editMealFormRules: {
        mealName: [
          { required: true, message: '请输入餐名', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '餐名的长度在2～10个字',
            trigger: 'blur'
          }
        ],
        mealDescription: [
          { required: true, message: '请输入餐描述', trigger: 'blur' },
          {
            min: 2,
            max: 30,
            message: '餐描述的长度在2～30个字',
            trigger: 'blur'
          }
        ],
        mealBelongDate: [
          { required: true, message: '请输入餐所属日期', trigger: 'blur' },
        ],
        mealType: [
          { required: true, message: '请输入餐类型', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
          {
            required: true,
            pattern: /^(0\.[1-9]\d*|[1-9]\d*(\.\d+)?)$/,
            message: '请输入大于0的数',
            trigger: 'blur'
          }
        ],
      },
      // 某天的餐品列表
      mealListOfDate:[],

      serverBaseUrl:serverBaseUrl
    }
  },
  created () {
    this.getMealList();
    this.getMealNameList();
    this.getMealImageList();
  },
  methods: {

    async getMealList () {
      //清空
      this.mealList=[];
      this.totle=0;
      //查询
      const { data: res } = await this.$http.get('/meal/query', {
        params: this.queryInfo
      })
      if (res.code !== 20) {
        return this.$message.error(res.msg)
      }else{
        this.mealList = res.data.result;
        this.totle = res.data.total;
      }
    },

    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagesize = newSize
      this.getMealList()
    },

    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagenum = newSize
      this.getMealList()
    },

    // 监听 添加餐品对话框的关闭事件
    addDialogClosed () {
      this.$refs.addMealFormRef.resetFields();
      this.mealListOfDate = [];
    },

    // 添加餐品
    addMeal () {
      // 提交请求前，表单预验证
      this.$refs.addMealFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        this.addMealForm.mealType = this.selectedMealTypeObj.id;
        // console.log(this.selectedMealTypeObj)
        const { data: res } = await this.$http.post('/meal/add', this.addMealForm)
        if (res.code !== 20) {
          this.$message.error(res.msg)
        }else{
          this.$message.success('添加餐品成功！')
        }
        
        // 隐藏添加餐品对话框
        this.addDialogVisible = false
        this.getMealList()
      })
    },

    // 编辑餐品信息
    async showEditDialog (id) {
      console.log("id="+id)
      const { data: res } = await this.$http.get('/meal/get?mealId=' + id)
      if (res.code !== 20) {
        return this.$message.error('查询餐品信息失败！')
      }
      this.editMealForm = res.data
      this.editDialogVisible = true
    },

    // 监听修改餐品对话框的关闭事件
    editDialogClosed () {
      this.$refs.editMealFormRef.resetFields()
    },

    // 修改餐品信息
    editMeal () {
      // 提交请求前，表单预验证
      this.$refs.editMealFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        const { data: res } = await this.$http.post('/meal/update',this.editMealForm)
        // 隐藏添加餐品对话框
        this.editDialogVisible = false
        if (res.code !== 20) {
          this.$message.error(res.msg)
        }else{
        this.$message.success('更新餐品信息成功！')
        }
        this.getMealList()
      })
    },

    // 删除餐品
    async removeMealById (id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该餐品, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/meal/delete?mealId=' + id)
      if (res.code !== 20) return this.$message.error('删除餐品失败！' + res.msg)
      this.$message.success('删除餐品成功！')
      this.getMealList()
    },

    async getMealNameList () {
      const { data: res } = await this.$http.get('/meal/name')
      if (res.code !== 20) {
        return this.$message.error('获取餐名列表失败！')
      }
      this.mealNameList = res.data.mealNameList
    },

    async getMealImageList () {
      const { data: res } = await this.$http.get('/meal/queryMealImages')
      if (res.code !== 20) {
        return this.$message.error('获取餐品图片列表失败！')
      }
      this.mealImageList = res.data.mealImageList
    },
    
    // 所属日期改变时
    async changeMealBelongDate(){
      const mealBelongDate = this.addMealForm.mealBelongDate;
      const { data: res } = await this.$http.get('/meal/queryByDate?mealBelongDate='+mealBelongDate);
      if (res.code !== 20) {
        // return this.$message.error('获取'+mealBelongDate+'餐列表失败！')
        this.mealListOfDate = [];
      }else{
        this.mealListOfDate = res.data.mealList;
      }
      
    },

    // 餐名是否可以编辑
    handleDisabled(item){
      if(this.addMealForm.mealBelongDate == undefined ||
        this.addMealForm.mealBelongDate == ''){
          return true;
      }else{
        return this.mealListOfDate.findIndex(item1=>item1.mealName==item.constantName) >= 0;
      }
    }
  },
  computed: {
    selectedMealTypeObj: function () {
      var selectedMealTypeObj = {};
      if(this.addMealForm.mealName == '餐一' || this.addMealForm.mealName == '餐二'){
        selectedMealTypeObj = {'id':'1','name':'早餐'};
      }else if(this.addMealForm.mealName == '餐三' || this.addMealForm.mealName == '餐四'){
        selectedMealTypeObj = {'id':'2','name':'午餐'};
      }else if(this.addMealForm.mealName == '餐五' || this.addMealForm.mealName == '餐六'){
        selectedMealTypeObj = {'id':'3','name':'晚餐'};
      }
      return selectedMealTypeObj;
	}
}
}
</script>

<style lang="less" scoped>
</style>
