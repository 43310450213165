<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-date-picker
              v-model="queryInfo.mealBelongDate"
              type="date"
              placeholder="选择预定日期"
              value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-button icon="el-icon-search" @click="getOrderList"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="orderList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="orderId" label="订单id"></el-table-column>
        <el-table-column prop="targetDate" label="预定日期"></el-table-column>
        <el-table-column prop="userName" label="用户姓名"></el-table-column>
        <el-table-column prop="totalAmount" label="订单总金额"></el-table-column>
        <el-table-column prop="orderStateName" label="订单状态"></el-table-column>
        <!-- <el-table-column :formatter="formatter1" label="餐一"></el-table-column> -->
        <el-table-column 
            v-for="(item) in mealNameList"
            :key="item.constantId"
            :label="item.constantName"
            :formatter="formatterMealNumber"
        ></el-table-column>

        <el-table-column prop="createDate" label="创建日期"></el-table-column>
        <el-table-column prop="lastModifyDate" label="最新修改日期"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        targetDate: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 5
      },
      orderList: [],
      totle: 0,
      selectedDate:'',
      // 餐名列表
      mealNameList:[],
    }
  },
  created () {
    this.getOrderList();
    this.getMealNameList();
  },
  methods: {
    async getOrderList () {
      //清空
      this.orderList=[];
      this.totle=0;
      //查询
      this.queryInfo.targetDate = this.selectedDate;
      const { data: res } = await this.$http.get('/order/query', {
        params: this.queryInfo
      })
      if (res.code != 20) {
        return this.$message.error(res.msg)
      }else{
        this.orderList = res.data.mealOrderList;
        this.totle = res.data.total;
      }
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagesize = newSize
      this.getOrderList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagenum = newSize
      this.getOrderList()
    },

    formatterMealNumber(row, column) {
      const mealBillList = row.mealBillList;
      const mealNameTemp = column.label;
      const res = mealBillList.find(item=>item.meal.mealName == mealNameTemp)
      if(res != undefined){
        return res.mealNumber;
      }else{
        return 0;
      }
    },

    async getMealNameList () {
      const { data: res } = await this.$http.get('/meal/name')
      if (res.code !== 20) {
        return this.$message.error('获取餐名列表失败！')
      }
      this.mealNameList = res.data.mealNameList
    }
  }
}
</script>

<style lang="less" scoped>
</style>
