<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>定时任务管理</el-breadcrumb-item>
      <el-breadcrumb-item>定时任务列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 面包屑导航区 -->

    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input placeholder="请输入描述" v-model="queryInfo.jobDescription" clearable @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getJobInfoList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 搜索 -->

      <!-- 定时任务信息列表区域 -->
      <el-table :data="jobInfoList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="jobId" label="任务id"></el-table-column>
        <el-table-column prop="jobDescription" label="任务描述"></el-table-column>
        <el-table-column prop="hour" label="时"></el-table-column>
        <el-table-column prop="minute" label="分"></el-table-column>
        <el-table-column prop="second" label="秒"></el-table-column>
        <el-table-column prop="jobStateName" label="任务状态"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              :enterable="false"
              placement="top"
            >
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="showEditDialog(scope.row.jobId)"
            ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 定时任务信息列表区域 -->
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      <!-- 分页区域 -->
    </el-card>
    <!-- 卡片视图 -->

    <!-- 修改定时任务信息的对话框 -->
    <el-dialog
      title="修改定时任务信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="editJobInfoForm"
        ref="editJobInfoFormRef"
        :rules="editJobInfoFormRules"
        label-width="100px"
      >
        <el-form-item label="任务id">
          <el-input v-model="editJobInfoForm.jobId" disabled></el-input>
        </el-form-item>
        <el-form-item label="任务描述">
          <el-input v-model="editJobInfoForm.jobDescription" disabled></el-input>
        </el-form-item>
        <el-form-item label="任务状态">
          <el-input v-model="editJobInfoForm.jobStateName" disabled></el-input>
        </el-form-item>
        <el-form-item label="时" prop="hour">
          <el-input v-model="editJobInfoForm.hour"></el-input>
        </el-form-item>
        <el-form-item label="分" prop="minute">
          <el-input v-model="editJobInfoForm.minute"></el-input>
        </el-form-item>
        <el-form-item label="秒" prop="second">
          <el-input v-model="editJobInfoForm.second"></el-input>
        </el-form-item>
      </el-form>
      <!-- 内容主体 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editJobInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改定时任务信息的对话框 -->

  </div>
</template>

<script>
import { serverBaseUrl } from "@/utils/common";

export default {
  data () {
    return {
      // 获取定时任务信息列表查询参数对象
      queryInfo: {
        jobDescription: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      //定时任务信息列表
      jobInfoList: [],
      totle: 0,
      // 修改定时任务信息
      editDialogVisible: false,
      editJobInfoForm: {},
      // 编辑定时任务信息表单验证
      editJobInfoFormRules: {
        hour: [
          { required: true, message: '请输入时刻', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1-5]?[0-9]$/,
            message: '请输入0~59的数',
            trigger: 'blur'
          }
        ],
        minute: [
          { required: true, message: '请输入分钟', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1-5]?[0-9]$/,
            message: '请输入0~59的数',
            trigger: 'blur'
          }
        ],
        second: [
          { required: true, message: '请输入秒', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1-5]?[0-9]$/,
            message: '请输入0~59的数',
            trigger: 'blur'
          }
        ],
      },

      serverBaseUrl:serverBaseUrl
    }
  },
  created () {
    this.getJobInfoList();
  },
  methods: {

    async getJobInfoList () {
      //清空
      this.jobInfoList=[];
      this.totle=0;
      //查询
      const { data: res } = await this.$http.get('/task/query', {
        params: this.queryInfo
      })
      if (res.code != 20) {
        return this.$message.error(res.msg)
      }else{
        this.jobInfoList = res.data.jobInfoList;
        this.totle = res.data.total;
      }
    },

    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagesize = newSize
      this.getJobInfoList()
    },

    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pagenum = newSize
      this.getJobInfoList()
    },

    // 编辑定时任务信息
    async showEditDialog (id) {
      console.log("id="+id)
      const { data: res } = await this.$http.get('/task/get?jobId=' + id)
      if (res.code != 20) {
        return this.$message.error(res.msg)
      }else{
        this.editJobInfoForm = res.data
      }
      this.editDialogVisible = true
    },

    // 监听修改定时任务信息对话框的关闭事件
    editDialogClosed () {
      this.$refs.editJobInfoFormRef.resetFields()
    },

    // 修改定时任务信息
    editJobInfo () {
      // 提交请求前，表单预验证
      this.$refs.editJobInfoFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        const { data: res } = await this.$http.post('/task/update',this.editJobInfoForm)
        // 隐藏添加定时任务信息对话框
        this.editDialogVisible = false
        if (res.code != 20) {
          this.$message.error(res.msg)
        }else{
          this.$message.success(res.msg)
        }
        this.getJobInfoList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
